/* ServiceOverview.css */

.service-overview {
    margin-top: 20px;
    padding: 10px 20px; /* Adds padding around the entire section */
    background-color: #f8f9fa; /* Light background color for the section */
}

.service-overview h2 {
    font-size: 2.5rem; /* Larger font size for the heading */
    font-weight: bold; /* Makes the heading bold */
    margin-bottom: 30px; /* Adds space below the heading */
    color: #333; /* Darker color for the heading */
}
.why-choose-us {
    color: #7a7979;

    font-size: 1.0rem;
    margin-top: 5px;
    margin-bottom: 20px;
}

.service-overview .service-item {
    padding: 20px; /* Adds padding around each service item */
    border-radius: 10px; /* Rounds the corners of each service item */
    background-color: #ffffff; /* White background for service items */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds subtle shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Adds transition effects */
    margin-bottom: 30px; /* Adds space below each service item */
}

.service-overview .service-item:hover {
    transform: translateY(-10px); /* Moves the service item up slightly on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Intensifies shadow on hover */
}

.service-overview img {
    max-height: 100px; /* Ensures images do not exceed a set height */
    margin-bottom: 15px; /* Adds space below images */
}

.service-overview h3 {
    font-size: 1.75rem; /* Font size for service titles */
    font-weight: 600; /* Bold service titles */
    margin-bottom: 10px; /* Adds space below titles */
    color: #fa7731; /* Custom color for service titles */
}

.service-overview p {
    font-size: 1rem; /* Standard font size for descriptions */
    color: #666; /* Grey color for descriptions */
    line-height: 1.6; /* Increases line spacing for readability */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .service-overview h2 {
        font-size: 2rem; /* Smaller font size for smaller screens */
    }

    .service-overview h3 {
        font-size: 1.5rem; /* Smaller font size for service titles on smaller screens */
    }

    .service-overview p {
        font-size: 0.9rem; /* Smaller font size for descriptions on smaller screens */
    }
}
