.location-summary {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 30px;
}

.location {
    width: 45%;
    margin-left: 20px;
    margin-right: 20px;
}

.location h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #20262c;
}

.location p {
    background-color: #282c34;
    color: #f8f9fa;
    padding: 10px;
    border-radius: 5px;
    font-size: 1rem;
}
.distance {
    margin-top: 20px;
}
.editable-location {
    cursor: pointer;
    color: #007bff;
    text-decoration: underline;
}

.editable-location:hover {
    color: #0056b3;
}