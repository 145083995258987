.special-items {
    background-color: rgba(240, 240, 240, 0.8);
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.special-items h4 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
}

.special-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.special-item select,
.special-item input {
    margin-right: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    flex: 1;
}

.remove-button {
    padding: 8px 12px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
}

.remove-button:hover {
    background-color: #ff1a1a;
}

.add-button {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 9px;
    cursor: pointer;
    width: 50%;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
}

.add-button:hover {
    background-color: rgba(252, 247, 247, 0.7);
}
