/* PromotionCode.css */

.promotion-code {
    border-top: 1px solid #e7e7e7;
    border-radius: 8px;
    margin-top: 30px;
    padding-top: 20px;
    margin-bottom: 40px;

}

.form-group {
    margin-bottom: 15px;
}

h1 {
    font-size: 10px;
    margin-bottom: 10px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.submit-button3, .submit-button3 {
    background-color: #fa7731; /* Green */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    transition: background-color 0.3s;
}

.submit-button3:hover, .submit-button3:hover {
    background-color: #bf3e03; /* Darker green */
}

.message {
    margin-top: 15px;
    font-size: 16px;
    color: #d9534f; /* Bootstrap danger color */
}

.submit-button2 {
    margin-top: 10px;
    background-color: #fa7731; /* Bootstrap primary color */
    width: 100%;
}

.submit-button2:hover {
    background-color: #bf3e03; /* Darker blue */
}
