/* Global Styles */
* {
    box-sizing: border-box;
}

body {
    margin: 0;
    overflow-x: hidden;
    font-family: 'Josefin Sans', sans-serif;
}

/* Container Styles */
.container {
    max-width: 100%;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
}

/* Header Styles */
.header {
    position: fixed;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    color: #fff;
    z-index: 1000;
    transition: background 0.5s ease;
    box-sizing: border-box;
    height: 80px;
}

/* Background change when scrolled */
.header.scrolled {
    background: rgba(0, 0, 0, 0.7);
}

/* Header Container */
.header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

/* Header Left Section */
.header-left {
    display: flex;
    align-items: center;
}

/* Site Title Styles */
.site-title {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    color: #fff;
    text-decoration: none;
}

/* Header Right Section */
.header-right {
    display: flex;
    align-items: center;
}

/* Phone Link Styles */
.phone-link {
    color: #fff;
    margin-right: 15px; /* Space between phone icon and 'About Us' */
    font-size: 1.5rem;
    text-decoration: none;
}

.phone-icon {
    font-size: 1.5rem;
}

/* About Us Link Styles */
.about-link {
    color: #fff;
    font-size: 1rem;
    text-decoration: none;

}

/* Hero Section Styles */
.hero-container {
    position: relative;
    overflow-x: hidden;
    margin-top: 0px; /* To prevent content from being hidden under the fixed header */
}

.hero-section {
    position: relative;
    height: 100vh;
    width: 100%;
    background: url('../assets/images/back.png') center/cover no-repeat;
}

.hero-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

/* Hero Content Styles */
.hero-content {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
    text-align: center;
    z-index: 2;
}

.hero-content h1 {
    font-size: 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #ffffff;
    margin-bottom: 20px;
}

.hero-content h2 {
    margin: 30px 0 80px;
}

.hero-content p {
    font-size: 1.5rem;
    color: #ff7637;
    margin-bottom: 30px;
}

/* Custom Button Styles */
.hero-content .btn-custom {
    background: linear-gradient(140.14deg, #ec540e 15.05%, #d6361f 114.99%) padding-box,
    linear-gradient(142.51deg, #ff9465 8.65%, #af1905 88.82%) border-box;
    border-radius: 10px;
    border: 2px solid transparent;
    height: 55px;
    text-shadow: 1px 1px 1px #00000040;
    box-shadow: 8px 8px 20px #45090059;
    padding: 10px 40px;
    line-height: 20px;
    cursor: pointer;
    transition: all 0.3s;
    color: white;
    font-size: 18px;
    font-weight: 500;
}

.hero-content .btn-custom:hover {
    box-shadow: none;
    opacity: 80%;
}

.hero-content .btn-custom:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Move Buttons Styles */
.btn2 {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 0.6em 1.3em;
    background: #fbca1f;
    font-family: inherit;
    font-weight: 900;
    font-size: 25px;
    border: 3px solid black;
    border-radius: 0.4em;
    box-shadow: 0.1em 0.1em;
    cursor: pointer;
    margin: 0 0 15px 15px;
    width: 90%;
    max-width: 100%;
    height: 100px;
    text-decoration: none;
    color: black;
}

.btn2.st, .btn2.sd {
    justify-content: flex-end;
}

.btn2.hm {
    justify-content: flex-start;
}

.btn2.sd {
    padding-right: 2.8em;
}

/* Button Image Styles */
.btn2-img{
    position: absolute;
    bottom: 0;
    left: 40%;
    transform: translateX(-50%);
    width: 80%;
    height: auto;
    pointer-events: none;

}


.btn3-img {
    position: absolute;
    bottom: 0;
    left: 65%;
    transform: translateX(-50%);
    width: 80%;
    height: auto;
    pointer-events: none;

}
.lang-switch {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px;
}

.lang-switch i {
    font-size: 20px;
    color: white; /* Set icon color to white */
}


.btn4-img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-60%);
    width: 80%;
    height: auto;
    pointer-events: none;

}
/* Button Hover Effects */
.move-buttons-container .btn2:hover {
    transform: translate(-0.05em, -0.05em);
    box-shadow: 0.15em 0.15em;
}
.btn-text {
    font-size: 1.2em;    /* Adjust font size as needed */
    margin-bottom: 10px; /* Space between text and image */
    color: black;        /* Adjust text color */
    z-index: 100;
    /* Add any other styles as needed */
    margin: 0;
    font-weight: bolder;
    -webkit-text-stroke: 0.1px wheat;
}
.move-buttons-container .btn2:active {
    transform: translate(0.05em, 0.05em);
    box-shadow: 0.05em 0.05em;
}

/* Sliding Image Container */
.sliding-image-container {
    position: absolute;
    bottom: 0;
    right: 0;
    overflow: hidden;
    width: 300px; /* Width of the image */
    height: auto;
    z-index: 100;
}

/* Sliding Image Styles */
.sliding-image {
    position: absolute;
    top: 0;
    left: 100%; /* Start off-screen to the right */
    width: 300px;
    height: auto;
    transform: translateX(0);
    transition: transform 1.5s ease-out;
}

.sliding-image.slide-in {
    transform: translateX(-100%); /* Slide into view */
}

/* Media Queries */

/* Mobile Styles */
@media (max-width: 768px) {
    /* Header Styles */
    .header {
        height: 60px;
    }

    .header .container {
        padding: 10px 20px;
    }

    /* Site Title */
    .site-title {
        font-size: 1.2rem;
    }

    /* Phone Link and About Us */
    .phone-link {
        font-size: 1.2rem;
        margin-right: 10px;
    }

    .about-link {
        font-size: 1rem;
    }

    /* Adjust Spacing in Header Right */
    .header-right {
        flex-direction: row;
    }

    /* Hero Content Adjustments */
    .hero-content {
        top: 25%;
        transform: translate(-50%, -25%);
    }

    .hero-content h1 {
        font-size: 2rem;
    }

    .hero-content h2 {
        margin: 40px 0 60px;
        font-size: 1.5rem;
    }

    /* Button Adjustments */
    .btn2 {
        font-size: 18px;
        width: 300px;
        max-width: 100%;
    }

    /* Sliding Image Adjustments */
    .sliding-image-container {
        width: 250px;
    }

    .sliding-image {
        width: 250px;
    }
}

/* Additional Styles if Needed */

/* Button Focus Styles */
.btn2:focus {
    outline: none;
}

/* Link Hover Effects */
.phone-link:hover, .about-link:hover {
    color: #ff7637; /* Change to desired hover color */
}

/* Responsive Image Styles */
img {
    max-width: 100%;
    height: auto;
}