.booking-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;

}

.booking-container h1 {
    text-align: center;
    font-size: 26px;
    color: #4CAF50;
    margin-bottom: 20px;

}

.booking-summary {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
}

.booking-info {
    font-size: 16px;
}

.booking-info p {
    margin: 10px 0;
}

.booking-info strong {
    font-weight: 600;
}

.thank-you-message {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
}

.contact-us {
    margin-top: 30px;
    text-align: center;
    font-size: 16px;
}

.contact-us a {
    color: #25D366; /* WhatsApp green */
    text-decoration: none;
    font-weight: bold;
}

.contact-us a:hover {
    text-decoration: underline;
}
