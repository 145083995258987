.cancellation-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.apology-message {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #d9534f;
}

.contact-us {
    margin-top: 30px;
}

.contact-us p {
    font-size: 16px;
    line-height: 1.5;
}

.contact-us a {
    color: #007bff;
    text-decoration: none;
}

.contact-us a:hover {
    text-decoration: underline;
}
