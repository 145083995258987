.move-options {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
}

.move-options h3 {
    font-size: 1.5rem;
    color: #ff8c00;
    margin-bottom: 10px;
}

.input-group {
    position: relative;
    display: inline-block;

    align-items: center; /* Align input and buttons vertically center */
    gap: 5px; /* Add gap between input and buttons */
}

.numberinput {
    width: 50px; /* Width of the input field */
    text-align: left;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex-grow: 1; /* Allow input to grow */
}

.increment-button{
    position: absolute;
    width: 45px;
    height: 50px;
    top: 11px;
    right: 45px;
    border:none;
    border-radius: 4px;
    background-color: #f9f9f9;
    cursor: pointer;

}
.decrement-button {
    position: absolute;
    width: 45px;
    height: 50px;
    top: 11px;
    right: 1px;
    background-color: #f9f9f9;
    border:none;
    border-color: #ddc8c8;

    cursor: pointer;

}

.increment-button:hover,
.decrement-button:hover {
    background-color: #ddd;
}

.move-type-button {
    padding: 10px 20px;
    font-size: 1.5rem;
    border: 1px solid #ccc;
    height: 140px;
    border-radius: 9px;
    cursor: pointer;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
}

.button-group {
    display: flex;
    gap: 20px;
    justify-content: center; /* Center the buttons horizontally */
    margin-bottom: 20px;
}

.move-type-button:hover {
    background-color: #ececec;
}
.boxoption{
    background-color: rgba(240, 240, 240, 0.8);
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}
.move-type-button.active {
    background-color: #ff8c00;
    color: #fff;
    border-color: #ff8c00;
}
.furniture-input{
    background-color: rgba(240, 240, 240, 0.8);
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}
.housebutt{
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 9px;
    cursor: pointer;
    width: 50%;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
}

.move-options select,
.move-options input {
    display: block;
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.student-options h4 {
    font-size: 1.2rem;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #333;
}

.student-options label {
    display: block;
    font-size: 1.3rem;
    margin-bottom: 15px;
}

.student-options input[type="checkbox"] {
    width: auto;
    margin-left: 10px;
}
/* Align the lift and stairs input in a row */
.lift-stairs-group {
    display: flex;
    gap: 20px; /* Space between lift and stairs input */
    align-items: center; /* Vertically center align items */
    margin-bottom: 10px; /* Space below the group */
}

/* Style for the lift and stairs options */
.lift-option, .stairs-input {
    flex: 1; /* Distribute space equally */
}
.lift-button {
    padding: 10px 20px;
    margin: 10px;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 40%;
    border-radius: 9px;
}

.lift-button.active {
    background-color: #ff9c34;
    color: #fff;

}


.deletebutton{
    background-color: #ff3e15;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
    border-radius: 9px;
    border: 1px solid #ccc;
}
.lift-stairs-group {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}
.checkbox-container {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    cursor: pointer;
}

.checkbox-input {
    width: 24px;  /* Adjust size as needed */
    height: 24px;
    cursor: pointer;
}

