.faq-section {
    margin-top: 2rem;
}

.faq-item {
    margin-bottom: 1rem;
    border-bottom: 1px solid #ddd;
    padding-bottom: 1rem;
}

.faq-question {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: #333;
}

.faq-answer {
    margin-top: 0.5rem;
    padding-left: 1rem;
    color: #555;
}

.faq-question span {
    font-size: 1.5rem;
    color: #333;
}

.faq-question:hover {
    color: #007bff;
}

.faq-answer p {
    margin: 0;
}
