/* DateTimePicker.css */

/* Ensure the picker is responsive */
/* Ensure the date picker appears above the input */
.rdtPicker {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 1000; /* Ensure it's on top of other elements */
    margin-bottom: 10px; /* Add some spacing from the input field */
}


.rdtDisabled {
    pointer-events: none;
    color: #d3d3d3;
}

.date-time-picker {
    position: relative;
    z-index: 1000;
}


/* Styling for smaller devices */
@media (max-width: 600px) {
    .date-time-picker {
        padding: 8px;
        font-size: 16px; /* Adjust font size for readability on smaller screens */
    }

    /* Ensure input fields and picker fit the width */
    .form-group input,
    .form-group .rdtPicker {
        width: 100%;
        box-sizing: border-box; /* Include padding and border in the element's total width and height */
    }

    /* Adjust DateTime picker styles for small screens */
    .rdtPicker {
        width: 100% !important; /* Ensure the picker width is 100% of the parent */
        max-width: 400px; /* Limit max-width */
        position: fixed; /* Prevent overflow by using fixed positioning */
        bottom: 0; /* Position at the bottom for easy accessibility */
        left: 0;
        right: 0;
        margin: auto; /* Center align the picker */
        z-index: 1000; /* Ensure it's on top of other elements */
    }

    .rdtPicker td.rdtDay:hover,
    .rdtPicker td.rdtDay.selected {
        background-color: #007bff;
        color: white;
    }

    .rdtTime td {
        width: 100%; /* Full width for better touch accuracy */
    }
}



.rdtTimeOption {
    padding: 5px 10px;
    cursor: pointer;
}


