.quote-summary {
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    margin-top: 20px;
}

.quote-summary h3 {
    font-size: 1.5rem;
    color: #ff8c00;
    margin-bottom: 15px;
}
.loader {
    font-size: 1.2rem;
    color: #666;
    text-align: center;
    margin-top: 20px;
}

.quote-summary h1 {
    color: #fa7731; /* Change this to your desired color */
    font-family: 'Josefin Sans', sans-serif; /* Apply the Google Font */
    font-size: 2rem; /* Adjust the font size */
    font-weight: 600; /* Adjust the font weight */
    margin-bottom: 7px; /* Add some space below the h2 */
    text-transform: uppercase; /* Optional: Transform text to uppercase */
    letter-spacing: 1px; /* Optional: Add some spacing between letters */
    text-align: center;
    margin-top: 7px;
}
.quote-details-container {
    background-color: rgba(128, 128, 128, 0.5); /* Transparent gray background */
    padding: 20px;
    border-radius: 8px;
    position: relative; /* Relative positioning for the confirm button */
}

.quote-details {
    text-align: left;
    color: #333;
    margin-bottom: 20px;
}

.quote-details div:last-child {
 padding-bottom: 10px;
}

.confirm-button-container {

    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.confirm-button {

    width: 150px;
    height: 40px;
    background-color: #fa7731;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.confirm-button:hover {
    background-color: #bf3e03;
}

/* QuoteSummary.css */

.quote-details-container {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-top: 20px;
}

.pricetag {
    padding-top: 10px;
    border: 1px solid #28a745; /* Green border */
    border-radius: 8px; /* Rounded corners */
    background-color: #e6ffed; /* Light green background */
    text-align: center;
    font-size: 1.2rem; /* Larger text for emphasis */
    color: #155724; /* Darker green text color */
    font-weight: bold; /* Bold text */
}

.confirm-button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center; /* Center the confirm button */
}



.confirm-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}
