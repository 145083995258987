/* Flex container to align icon and input */
.map-input-container {
    display: flex; /* Use Flexbox */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically if height is set */
    width: 100%; /* Full width of the parent container */
    padding: 10px 0; /* Padding for spacing */
    flex-direction: column; /* Stack elements vertically to ensure input and icon align properly */
}

/* Styling for the Google Maps input field */
.map-input-container input[type="text"] {
    box-sizing: border-box;
    border: 1px solid #ccc;
    width: 100%; /* Fixed width for the input field */
    height: 45px;
    padding: 0 15px;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for the input field */
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    margin-bottom: 10px;
    display: block;
}

.map-autocomplete {
    width: 80%; /* Fixed width for the input field */
}

/* Focus state for input field */
.map-input-container input[type="text"]:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.25); /* Shadow effect on focus */
}

/* Style for the map container */
.map-container {
    height: 200px;
    width: 80%; /* Width of the map container */
    margin: 10px auto; /* Centers the map-container horizontally */
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    display: block; /* No need for flexbox here to center the container itself */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically if height is set */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds a shadow for better visual appearance */
}

/* Style for the borderless dropdown select field */
.select {
    border: none;          /* Removes the border */
    outline: none;         /* Removes the outline */
    background-color: transparent;  /* Makes background transparent, if needed */
    font-size: 16px;       /* Adjust font size to your preference */
    padding: 8px;          /* Adjust padding for spacing */
    width: 100%;           /* Makes the select box full width */
    appearance: none;      /* Removes the default dropdown arrow */
    text-align: center;
}

/* Optional: style for the dropdown container */
.address-dropdown {
    position: relative;
    margin-top: 10px; /* Add some space above the dropdown */
    margin-bottom: 20px; /* Add space below the dropdown */
    padding: 10px; /* Add padding around the dropdown for spacing */
    background-color: #ffffff; /* Light background color for distinction */
    border-radius: 5px; /* Rounded corners for the dropdown container */
    /* box-shadow: none; /* Remove or comment out this line to remove the shadow */
    text-align: center;
    width: 80%;
}

/* Optional: Add a custom arrow to the dropdown */
.select::after {
    content: '▼';          /* Add a custom arrow */
    position: absolute;
    right: 10px;           /* Adjust arrow position */
    pointer-events: none;  /* Prevents the arrow from blocking clicks */

}

/* Style for the select dropdown itself */
/* Update the select element to allow text wrapping */
.address-dropdown select {
    width: 100%; /* Full width for the dropdown */
    padding: 0px; /* Padding inside the select element */
    border: 0px solid #ddd; /* Light border around the select */
    border-radius: 4px; /* Rounded corners for the select box */
    background-color: #fff; /* White background for the dropdown */
    font-size: 16px; /* Larger font size for readability */
    color: #333; /* Darker text color */

    margin-bottom: 10px;
    text-align: center;
    appearance: none; /* Remove default arrow for more control over styling */
    -webkit-appearance: none; /* Remove default arrow in Safari */
    -moz-appearance: none; /* Remove default arrow in Firefox */
    cursor: pointer; /* Cursor changes to pointer on hover */
    white-space: normal; /* Allows text to wrap within the dropdown */
    overflow-wrap: break-word; /* Ensures long words break and wrap properly */
    word-wrap: break-word; /* Ensures long words break and wrap properly in legacy browsers */
    word-break: break-word; /* Breaks the words at the edge of the select element */
    overflow: hidden; /* Ensures that text doesn't overflow */
}

/* Style for options within the select dropdown */
.address-dropdown select option {
    padding: 10px; /* Padding for each option */
    font-size: 16px; /* Larger font size for readability */
    color: #333; /* Dark text color for options */
    text-align: left;
    white-space: normal; /* Allows text to wrap within the options */
    overflow-wrap: break-word; /* Ensures long words break and wrap properly */
    word-wrap: break-word; /* Ensures long words break and wrap properly in legacy browsers */
    word-break: break-word; /* Breaks the words at the edge of the option element */
}


/* Styling the dropdown arrow */
.address-dropdown select::after {
    content: '▼'; /* Unicode character for a downward arrow */
    font-size: 12px;
    color: #666;
    position: absolute;
    right: 10px;
    top: calc(50% - 5px);
    pointer-events: none;
}

/* Hover effect for the select box */
.address-dropdown select:hover {
    border-color: #aaa; /* Darker border on hover */
}

/* Focus effect for the select box */
.address-dropdown select:focus {
    outline: none; /* Remove default outline */
    border-color: #007bff; /* Blue border when focused */
    /* box-shadow: none; /* Remove or comment out this line to remove the shadow */
}
